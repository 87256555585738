import { LayoutFrame, NavButton } from 'app-layout-frame';
import {
    DropdownList,
    DropdownListDivider,
    DropdownListItem,
    IconBell,
    IconClock,
    IconSignOut,
    IconUserLight,
    IconVolume,
    IconVolumeMute,
    PersistentBanner,
    Spinner
} from '@7shifts/sous-chef';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Logo from 'sharedComponents/Logo';
import { useAuthContext } from './contexts/AuthContext';
import { __ } from './i18n';
import { getRemainingDaysToExpire } from './domain/membership';
import { getNavItems } from './domain/navItems';
import {
    WhatsAppProvider,
    useWhatsContext
} from './contexts/WhatsAppContext/WhatsAppContext';
import UserStatusBullet from 'sharedComponents/UserStatusBullet';
import { changeStatus } from './api/usersApi';
import NotificationsProvider from 'sharedComponents/NotificationsProvider';
import NoChannelPersistentBanner from 'sharedComponents/NoChannelPersistentBanner';
import { User } from 'types';
import { useNotificationContext } from 'sharedComponents/NotificationsProvider/NotificationContext';
import { useLocalStorage } from 'hooks/useLocalStorage';

const App = () => {
    const { handleLogout, isAuth, loading, user } = useAuthContext();
    const navigate = useNavigate();
    const onLogout = () => {
        handleLogout().then(() => {
            navigate('/login?logged_out=1');
        });
    };

    if (loading) {
        return <Spinner block />;
    }

    if (!isAuth && !loading) {
        if (
            window.location.pathname &&
            window.location.pathname.indexOf('login') === -1
        ) {
            return (
                <Navigate
                    to={`/login?urlReturn=${window.location.pathname}${window.location.search}`}
                />
            );
        }
        if (
            window.location.search &&
            window.location.search.indexOf('logged_out') > 0
        ) {
            return <Navigate to={`/login?logged_out=1`} />;
        }
        return <Navigate to={`/login`} />;
    }

    if (!user) {
        throw new Error('User is not present! This should never happen!');
    }

    return (
        <NotificationsProvider userId={user.id} companyId={user.companyId}>
            <WhatsAppProvider>
                <Layout user={user} onLogout={onLogout} />
            </WhatsAppProvider>
        </NotificationsProvider>
    );
};

type LayoutProps = {
    user: User;
    onLogout: () => void;
};
const Layout = ({ user, onLogout }: LayoutProps) => {
    const { whatsApps } = useWhatsContext();
    const remainingDaysToExpire = getRemainingDaysToExpire();
    const navItems = getNavItems(user, whatsApps);
    const [, setUserSoundEnabledFromStorage] = useLocalStorage(
        'userSoundEnabled',
        true
    );
    const [, setUserPopupEnabledFromStorage] = useLocalStorage(
        'userPopupEnabled',
        true
    );
    const {
        setUserNotificationEnabled,
        setUserNotificationSoundEnabled,
        userNotificationEnabled,
        userNotificationSoundEnabled
    } = useNotificationContext();
    return (
        <LayoutFrame
            navItems={navItems}
            banner={
                <>
                    <NoChannelPersistentBanner />
                    {user.profile === 'admin' && remainingDaysToExpire < 5 && (
                        <PersistentBanner>
                            {__('main.membershipExpires', {
                                count: remainingDaysToExpire
                            })}
                        </PersistentBanner>
                    )}
                </>
            }
            appLogo={<Logo />}
            companyName={user.company.name}
            userPrifileImageURL=""
            accountMenuDropdown={
                <DropdownList>
                    {user.statusAvailable ? (
                        <DropdownListItem
                            prefix={<IconClock />}
                            onClick={() => changeStatus(user.id, 'away')}
                        >
                            {__('main.setAsAway')}
                        </DropdownListItem>
                    ) : (
                        <DropdownListItem
                            prefix={<IconClock />}
                            onClick={() => changeStatus(user.id, 'available')}
                        >
                            {__('main.setAsAvailable')}
                        </DropdownListItem>
                    )}
                    <DropdownListDivider />
                    <DropdownListItem
                        href="/my-account"
                        prefix={<IconUserLight />}
                    >
                        {__('main.myAccount')}
                    </DropdownListItem>
                    <DropdownListItem
                        onClick={onLogout}
                        prefix={<IconSignOut />}
                    >
                        {__('main.logOut')}
                    </DropdownListItem>
                </DropdownList>
            }
            actions={[
                <UserStatusBullet key={'user-status-bullet'} />,
                <div key={'user-sound-handling'}>
                    {userNotificationSoundEnabled ? (
                        <NavButton
                            onClick={() => {
                                setUserSoundEnabledFromStorage(false);
                                setUserNotificationSoundEnabled(false);
                            }}
                            key={'userSoundEnabled-enabled'}
                            tooltipContent={__('main.setSoundDisable')}
                        >
                            <IconVolume color="var(--color-mint-500)" />
                        </NavButton>
                    ) : (
                        <NavButton
                            onClick={() => {
                                setUserSoundEnabledFromStorage(true);
                                setUserNotificationSoundEnabled(true);
                            }}
                            key={'userSoundEnabled-disabled'}
                            tooltipContent={__('main.setSoundEnabled')}
                        >
                            <IconVolumeMute color="var(--color-banana-500)" />
                        </NavButton>
                    )}
                </div>,
                <div key={'user-notification-handling'}>
                    {userNotificationEnabled ? (
                        <NavButton
                            onClick={() => {
                                setUserNotificationEnabled(false);
                                setUserPopupEnabledFromStorage(false);
                            }}
                            key={'userPopupEnabled-enabled'}
                            tooltipContent={__('main.setSoundDisable')}
                        >
                            <IconBell color="var(--color-mint-500)" />
                        </NavButton>
                    ) : (
                        <NavButton
                            onClick={() => {
                                setUserPopupEnabledFromStorage(true);
                                setUserNotificationEnabled(true);
                            }}
                            key={'userPopupEnabled-disabled'}
                            tooltipContent={__('main.setPopupEnabled')}
                        >
                            <IconBell color="var(--color-banana-500)" />
                        </NavButton>
                    )}
                </div>
            ]}
        >
            <Outlet />
        </LayoutFrame>
    );
};

export default App;
