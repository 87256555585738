import App from './App';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider
} from 'react-router-dom';
import { useAuthContext } from './contexts/AuthContext';
import { ROUTES as CHANNEL_ROUTES } from './modules/Channels';
import { ROUTES as TAGS_ROUTES } from './modules/Tags';
import { ROUTES as USERS_ROUTES } from './modules/Users';
import { ROUTES as TEMPLATES_ROUTES } from './modules/Templates';
import { ROUTES as CONTACTS_ROUTES } from './modules/Contacts';
import { ROUTES as SCHEDULES_ROUTES } from './modules/Schedules';
import { ROUTES as QUICK_MESSAGES_ROUTES } from './modules/QuickMessages';
import { ROUTES as MESSAGE_TEMPLATES_ROUTES } from './modules/MessageTemplates';
import { ROUTES as QUEUES_ROUTES } from './modules/Queues';
import { ROUTES as SETTINGS_ROUTES } from './modules/Settings';
import { ROUTES as INVOICES_ROUTES } from './modules/Invoices';
import { ROUTES as MY_ACCOUNT_ROUTES } from './modules/MyAccount';
import { ROUTES as DASHBOARD_ROUTES } from './modules/Dashboard';
import { ROUTES as TICKETS_ROUTES } from './modules/Tickets';
import { ROUTES as AUTH_ROUTES } from './modules/Auth';
import ErrorBoundary from 'sharedComponents/ErrorBoundary';

const Router = () => {
    const { user } = useAuthContext();
    const isAdmin = user && user.profile === 'admin';
    const ADMIN_ROUTES = isAdmin
        ? [
              ...SETTINGS_ROUTES,
              ...INVOICES_ROUTES,
              ...QUEUES_ROUTES,
              ...CHANNEL_ROUTES,
              ...USERS_ROUTES,
              ...TEMPLATES_ROUTES
          ]
        : [];

    const routes = createBrowserRouter([
        ...AUTH_ROUTES,
        {
            path: '/',
            element: <App />,
            errorElement: <ErrorBoundary />,
            children: [
                ...TICKETS_ROUTES,
                ...MESSAGE_TEMPLATES_ROUTES,
                ...DASHBOARD_ROUTES,
                ...MY_ACCOUNT_ROUTES,
                ...QUICK_MESSAGES_ROUTES,
                ...SCHEDULES_ROUTES,
                ...TAGS_ROUTES,
                ...CONTACTS_ROUTES,
                ...ADMIN_ROUTES,
                {
                    path: '/',
                    element: <Navigate replace to="/dashboard" />
                },
                {
                    path: '*',
                    element: <Navigate replace to="/dashboard" />
                }
            ]
        }
    ]);
    return <RouterProvider router={routes} />;
};

export default Router;
